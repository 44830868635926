// Reports
// -------------------------

.reports {

    .reports-tab-container {
        margin-top: 25px;
    }


    .reports-frame-style {
        height: 100vh;
        width: 100%;
        border: 1px solid $ls-color-gray;
        border-radius: 7px;
    }

    .report-button {
        width: auto;
    }

    .report-view-button {
        display: none;
        color: $btn-secondary-color;
    }

    .report-fields-container {
        margin-top: 25px;
    }

    .report-field-container {
        margin-top: auto;
    }

    .report-submit-button-container {
        margin-top: 32px;
    }

    .report-label {
        font-size: 14px;
        line-height: 21px;
        color: $ls-color-secondary;
    }

    .report-label {
        font-weight: 500;
        color: #55547A;
        font-size: 14px;
    }
}


@media(max-width: $x-small-breakpoint ) {

    .reports {

        .reports-tab {
            padding: 10px 19px;
        }

        .reports-frame-style {
            display: none;
        }

        .report-submit-button-container {
            display: none;
        }

        .report-view-button {
            display: flex;
            color: $btn-secondary-color !important;
            justify-content: center
        }

        .report-button {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .report-fields-container {
            margin-top: 0px;
        }

        .report-field-container {
            margin-top: 20px;
        }

        .tabs {

            .tab {
                padding: 10px 15px;

                .tab-link {
                    padding: 10px 17px !important;
                }
            }

        }
    }

}
