.search-select {
    position: relative;
    width: 100%;

    .activator {
        position: relative;
        width: 100%;
        cursor: pointer;
    }

    .selector-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 100;
        width: 100%;
        top: 0;
        background: #FFFFFF;
        box-shadow: $shadow-lg;
        border-radius: 5px;
    }

    .selector-menu-above {
        top: unset !important;
        bottom: 100% !important;
    }
}
