// Dashboard - Create & Index
// -------------------------

.customer-create {

    .section-title {
        font-style: normal;
        font-weight: 500;
        font-size: 17.5px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #000000;
    }

    .form-label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $ls-color-secondary;
    }

    .customer-card {
        border: 1px solid $ls-color-gray--light;
        padding: 10px;
    }

    .same-address-checkbox-container {
        display: flex;
        margin-bottom: 1.5rem;
        align-items: center;
        justify-content: flex-end;
    }

    hr {
        margin-bottom: 1.8rem;
        border: 1px solid $ls-color-gray--light;
    }

    .header-button-container {
        display: block;
    }

    .collapse-button-container {
        display: none;

        .collapse-button {
            width: 100%;
            display: flex;
            justify-content: center;
        }

    }

}

@media(max-width: $x-small-breakpoint ) {

    .customer-index {

        .page-actions {
            position: relative;
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            display: flex;
            flex-wrap: wrap;

            button {
                margin-bottom: 10px;
            }

        }

    }

    .customer-create {

        .page-actions {
            position: relative;
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            display: flex;
            flex-wrap: wrap;

            button {
                margin-bottom: 10px;
            }

        }

        .same-address-checkbox-container {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 0rem;
        }

        hr {
            margin-bottom: 1.2rem;
            border: 1px solid $ls-color-gray--light;
        }

        .section-title {
            padding: 15px;
        }

        .collapse-button-container {
            display: block;
        }

        .header-button-container {
            display: none;
        }

    }

}
