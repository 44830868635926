.dropdown-group {
    position: relative;

    &.active .dropdown-container {
        display: block;
    }

    .dropdown-container {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: .5rem;
        margin: .125rem 0 0 .125rem;
        font-size: 1rem;
        color: $dropdown-link-color;
        text-align: left;
        list-style: none;
        background-color: $dropdown-bg;
        background-clip: padding-box;
        border-radius: .25rem;
        box-shadow: $shadow !important;
        border: none;

        &.align-right {
            right: 0;
            left: auto;
        }

        .dropdown-group {
            display: block;
            margin: 0;
        }

        .dropdown-group-item .dropdown-item {
            display: block;
            font-size: 15px;
            padding: 8px 12px;
            font-weight: 300;
            color: $ls-color-black;
            background-color: transparent;
            border-radius: 5px;

            &:hover {
                background-color: $ls-color-gray--light;
            }

            &.active {
                color: $ls-color-primary;
            }

            i {
                color: $ls-color-gray--dark;
                margin-right: 5px;
            }
        }

        .dropdown-activator {
            position: relative;

            &::after {
                content: none !important;
            }

            &::before {
                border-color: $dropdown-link-color transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0;
                content: '';
                display: block;
                height: 0;
                position: absolute;
                right: 10px;
                top: 18px;
                transition: all .2s ease-in-out;
                width: 0;
                transform: rotate(90deg);
            }
        }

        .dropdown-group.active .dropdown-activator {
            a {
                color: $dropdown-header-color;
            }

            &::before {
                transform: rotate(-90deg);
                border-color: $ls-color-primary transparent transparent;
            }
        }

        .dropdown-container {
            display: block;
            margin: 0;
            left: 100%;
            top: 0;
            position: absolute;
            margin: .125rem .125rem 0;

            &.align-right {
                right: 100%;
                left: auto;
            }
        }

        .dropdon-group-item {
            position: relative;
        }
    }

    .dropdown-item-divider {
        border-top: 1px solid $white;
        height: 1px;
        margin: .5rem 0;
        overflow: hidden;
    }
}

.dropdown-group.dropdown-light {

    .dropdown-container {
        color: $ls-color-black;
        background-color: $white;
        border: 1px solid lighten($ls-color-gray--dark, 10%);

        .dropdown-group-item a {
            color: $ls-color-black;
        }

        .dropdown-group-item a.active {
            color: $ls-color-black;
            background-color: lighten($ls-color-gray, 7%);
        }

        .dropdown-group-item a:hover {
            background-color: lighten($ls-color-gray, 7%);
        }

        .dropdown-activator::before {
            border-color: $ls-color-gray--dark transparent transparent;
        }
    }

    .dropdown-container .dropdown-group.active .dropdown-activator {
        a {
            color: $ls-color-black;
            background-color: lighten($ls-color-gray, 7%);
        }

        &::before {
            border-color: $ls-color-gray--dark transparent transparent;
        }
    }

    .dropdown-item-divider {
        border-top: 1px solid $ls-color-black;
    }
}

.dropdown-item {

    .dropdown-item-icon {
        width: 20px;
        height: 14px;
        margin-right: 6px;
        color: $ls-color-secondary;
    }
}

.action {
    position: absolute;
    right: 0;
    z-index:1;

    .action-btn {
        color: $ls-color-primary;
        background: transparent;
        border: none;
    }
}
