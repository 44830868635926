// Estimates - Index
// -------------------------

.estimate-index-page {

    .table-actions {

        position: relative;
        border: none;

        .table-actions-button {
            position: absolute;
            right: 0px;
            top: -15px;
        }

        .table-stats {
            margin: 0;
            position: absolute;
            right: 0px;
            top: 40px;
        }
    }

    .filter-container {
        display: flex;
        justify-content: space-between;
    }

    .filter-customer {
        margin-right: 40px;
        flex: 1;
    }

    .filter-status {
        margin-right: 40px;
        flex: 1;
    }

    .filter-date {
        flex: 2;
        display: flex;
        margin-right: 40px;
        align-items: center;
        .from {
            flex: 1;
        }
        .to {
            flex: 1;
        }
    }

    .filter-estimate {
        flex: 1;
    }

    .dashed {
        border: 1px solid #B9C1D1;
        width: 10px;
        margin-top: 28px;
    }

    @media(max-width: $large-breakpoint ) {
        .filter-container {
            flex-direction: column;
        }

        .filter-estimate {
            margin-right: 40px;
        }

        .filter-customer,
        .filter-status,
        .filter-date,
        .filter-estimate {
            margin-bottom: 10px;
            margin-right: 0px;
        }
    }

    @media(max-width: $x-small-breakpoint) {
        .page-actions {
            position: relative;
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            display: flex;
            flex-wrap: wrap;

            button {
                margin-bottom: 10px;
            }

        }
    }

}
