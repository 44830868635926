.base-modal .modal-body {
    overflow: visible !important;
 }

.item-modal {

    .card-footer {
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px;
    }

    .col-form-label {
        text-align: end;
        padding-right: 0;
        position: relative;
    }

    .required {
        position: absolute;
        right: -10px;
        color: $ls-color-red;
    }

}


@media(max-width: $x-small-breakpoint ) {

    .base-modal .item-modal .input-label{

        .item-modal {
            width: 100vw;

            .input-label {
                text-align: left;
            }

        }

        .required {
            position: absolute;
            right: auto;
            margin-left: 5px;
            color: #FB7178;
        }

        text-align: left;

    }

}
