.base-switch {

    input[type=checkbox] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 35px;
        height: 16px;
        background: $white;
        border: 1px solid $ls-color-gray;
        display: block;
        border-radius: 16px;
        position: relative;
    }

    label:after {
        content: "";
        position: absolute;
        top: -3px;
        left: 0px;
        width: 20px;
        height: 20px;
        background: $ls-color-gray--dark;
        border-radius: 15px;
        transition: 0.3s;
    }

    input:checked+label {
        background: $ls-color-primary--very-light;
    }

    input:checked+label:after {
        left: calc(100% - 0px);
        transform: translateX(-100%);
        background: $ls-color-primary;
    }

    label:active:after {
        width: 20px;
    }
}
