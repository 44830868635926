// Basic Styles
// -------------------------

html, body , .site-wrapper{
    height: 100%;
    font-size: 14px;
}

body {
    background-color: $ls-color-gray--light;
    font-family: $base-font-family;
    color: $base-font-color;

    overflow-x: hidden;

    i {
        font-family: 'Font Awesome 5 Free'!important;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: $heading-font-color;
    font-weight: bold;
}

a {
    color: $ls-color-black--light;

    &:hover, &:focus {
        color: lighten($ls-color-black--light, 10%);
        text-decoration: none;
        outline: none;
    }

}

mark, .mark {
    background-color: #efefef;
    border-radius: 5px;
    color: $ls-color-black;
    padding: 0.2em 0.4em;
    margin: 0 1px;
}

code, .code {
    background-color: $code-bg;
    color: $ls-color-primary;
}

:active, :focus, :hover {
    outline: 0;
}

[v-cloak] {display: none}

.text-default {
    color: $ls-color-black !important;
}

@keyframes spinner {
    to { transform: rotate(360deg); }
}

.fa-spinner {
    animation: spinner 1.5s linear infinite;
}

.filter-section {
    background: $ls-color-gray--light;
    position: relative;
    z-index: 2;
    padding: 30px;
    border-radius: 5px;
}

.clear-filter {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 14px;
    line-height: 21px;
    color: $ls-color-black;
    cursor: pointer;
}

.list-add-button {
    display: flex;
    width: 100%;
    padding: 13px 10px;
    background:$ls-color-gray--light;
    justify-content: center;
    border: none;
    outline: none;
    align-items: center;

    .icon {
        color: $ls-color-primary--light;
    }

    label {
        font-family: Poppins;
        font-size: 14px;
        margin: 0 0 0 10px !important;
        line-height: 14px;
        color: $ls-color-primary--light;
    }
}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swal-icon--custom {
    height: 70px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $ls-color-primary !important;
    background-color: $ls-color-primary !important;
}

.avatar-cropper-btn:hover  {
    background-color: $ls-color-primary !important;
    color: $white;
}
