.image-upload-box {
    height: 110px;
    padding: 20px;
    background-color: transparent;
    border: 2px dashed $ls-color-gray--light;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    .preview-logo {
        max-height: 80%;
        position: absolute;
        opacity: 1;
        animation: fadeIn 2s ease;
    }

    .upload-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .upload-text {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $ls-color-gray;
    }

    .upload-icon {
        font-size: 20px;
        line-height: 23px;
        color: $ls-color-gray;
        margin-bottom: 10px;
    }

    .white-icon {
        font-size: 30px;
        line-height: 23px;
        color: $white;
        margin-bottom: 10px;
    }

    .overlay {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        transition: .5s ease;
        background-color: rgba(0,0,0,0.5);
        opacity: 0;
    }

    &:hover {
        .overlay {
            opacity: 1;
        }
    }

}

.avatar-upload {
    height: 130px;
    width: 130px;
    .preview-logo {
        max-width: 80% !important;
    }

    @keyframes fadeIn{
      0%{
        opacity: 0;
      }
      100%{
        opacity: 1;
      }
    }
}


