.base-prefix-input {
    display: flex;
    position: relative;
    width: 100%;
    height: 40px;
    padding: 2px 2px;
    flex-direction: row;
    background: #FFFFFF;
    border: 1px solid $ls-color-gray--light;
    border-radius: 5px;

    .icon {
        width: 13px;
        height: 18px;
        color: $ls-color-gray;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 16px;
        margin-top: 17px;
        margin-left: 20px;
        z-index: 1;
        transform: translate(-50%,-50%);
    }

    p {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
    }

    .prefix-label {
        display: flex;
        height: 18px;
        color: #55547A;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        padding: 9px 2px 9px 10px;
    }

    .prefix-input-field {
        width: 100%;
        padding: 8px 13px;
        padding-left: 1px;
        text-align: left;
        background: #FFFFFF;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
}

