// Gauges
// -------------------------

.gauge {
    position: relative;
    display: flex;
    align-items: center;
}

.gauge-lg {

    .basic-gauge, .donut-gauge, .color-gauge {
        height: 250px;
    }

    .gauge-label {
        font-size: 40px;
    }

}

.gauge-md {

    .basic-gauge, .donut-gauge, .color-gauge {
        height: 180px;
    }

}

.gauge-sm {

    .basic-gauge, .donut-gauge, .color-gauge {
        height: 120px;
    }

    .gauge-label {
        font-size: 20px;
    }

}

.gauge-label {
    display: inline-block;
    position: absolute;
    top: 60%;
    left: 0px;
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-weight: bold;

    &.middle-label {
        top: 50%;
        transform: translateY(-50%);
    }
}

.gauge {
    position: relative;
}

@media(max-width: $x-small-breakpoint) {

    .gauge-lg {

        .basic-gauge, .donut-gauge, .color-gauge {
            height: 180px !important;
            width: auto !important;
        }

        .gauge-label {
            font-size: 28px;
        }

    }

}
