.template-modal {

    .template-container {
        display: flex;
        justify-content: flex-start;
        overflow-x: auto;
        flex-wrap: wrap;
        padding: 5px;
    }

    .template-img {
        margin: 12px;
        border: 1px solid $ls-color-gray--light;
    }

    .selected-template {
        border: 1px solid $ls-color-primary;
        position: relative;
    }

    .check-icon {
        width: 20px;
        height: 20px;
        position: absolute;
        color: $ls-color-primary;
        top: -6px;
        right: -5px;
        z-index: 10;
    }

    .card-footer {
        display: flex;
        justify-content: flex-end;
        padding: 20px 20px;

        .btn-app {
            margin-left: 15px;
        }
    }
}

@media (max-width: $x-small-breakpoint) {

    .base-modal {

        .template-modal {
            width: 100vw;

            .template-container {
                justify-content: center;
            }

        }

    }

}
